@use 'sass:math';
@import 'variables';
@import 'spacing';
@import 'functions';

$tag-font-size: 12px;

.tag {
    border: $border-primary;
    border-radius: 16px;
    height: 24px;
    line-height: rem-calc(18px);
    padding: 0 $spacing-xs;
    width: max-content;

    &__primary-text {
        color: $color-grey-100;
        font-size: rem-calc($tag-font-size);
        font-weight: $font-weight-500;
        line-height: rem-calc(18px);
        padding-top: math.div($spacing-xs, 4);
        text-align: center;
    }

    &--wu {
        background: $color-orange-10;
        border-color: $color-orange-100;
    }

    &--oa {
        background: $color-aqua-10;
        border-color: $color-navy-60;
    }
}
