@use 'sass:math';
@import 'colors', 'variables', 'spacing', 'functions';

.static-panel {
    background: $color-white;
    border: $border-primary;
    border-radius: $border-radius-global;
    color: $color-grey-100;
    display: flex;
    flex-direction: column;
    gap: $spacing-m;
    padding: $spacing-s $spacing-m;

    &__header {
        align-items: center;
        box-sizing: border-box;
        color: $color-grey-100;
        display: flex;
        font-weight: $font-weight-800;
        gap: $spacing-s;
        width: 100%;
    }

    &__header-actions {
        display: flex;
        gap: $spacing-xs;

        &:empty {
            display: none;
        }
    }

    &__header-title {
        align-items: center;
        display: flex;
        flex-grow: 1;
        gap: math.div($spacing-m, 2);
        line-height: rem-calc(40px);
        word-break: break-word;
    }

    &__header-title-text {
        display: flex;
        font-size: rem-calc(20px);

        &--bright {
            color: $color-grey-100;
        }

        &--pale {
            color: $color-grey-60;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: $spacing-m;
        padding-bottom: $spacing-xs;
    }

    &__content-notice {
        color: $color-grey-80;
        line-height: rem-calc(24px);
    }

    &__content-footer {
        display: flex;
        gap: math.div($spacing-m, 2);
        justify-content: flex-end;

        &:empty {
            display: none;
        }
    }
}
