@use 'sass:math';
@import 'plan', 'colors', 'spacing';

.error-modal {
    align-items: center;
    background-color: $color-red-10;
    color: $color-grey-100;
    display: flex;
    gap: math.div($spacing-m, 2);
    margin-bottom: math.div($spacing-s, 2);
    padding: math.div($spacing-m, 2) $spacing-s;

    svg-icon {
        fill: $color-red-primary;
    }
}
