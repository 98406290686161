@use 'sass:math';
@import 'spacing', 'mixins';

.form {
    margin-left: -$spacing-xs;
    margin-right: -$spacing-xs;

    &__row {
        display: flex;
        width: 100%;
    }
}
