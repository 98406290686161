@use 'sass:math';
@import 'colors', 'variables', 'functions';

$displayed-lines: 2;

.card-item {
    background-color: $color-white;
    border: $border-primary;
    border-radius: $border-radius-global;
    display: flex;
    flex-direction: column;
    gap: math.div($spacing-m, 2);
    height: 198px;
    justify-content: space-between;
    padding: $spacing-m;
    width: 262px;

    &:hover {
        box-shadow: 0 2px 12px rgba(0 0 0 / 25%);
    }

    &:disabled {
        background-color: $color-grey-20;
        box-shadow: none;
        cursor: default;
    }

    &__top-content {
        display: flex;
        flex-direction: column;
        gap: math.div($spacing-m, 2);
        width: 100%;

        &--no-gap {
            gap: 0;
        }
    }

    &__status-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__type {
        border-radius: math.div($border-radius-global, 2);
        display: flex;
        font-size: rem-calc(14px);
        line-height: rem-calc(20px);
        padding: math.div($spacing-xs, 2) $spacing-xs;

        &--import {
            background-color: $color-green-10;
        }

        &--export {
            background-color: $color-orange-10;
        }

        &--draft-report {
            background-color: $color-yellow-10;
            border: 1px solid $color-yellow-50;
        }

        &--final-report {
            display: none;
        }
    }

    &__name {
        -webkit-box-orient: vertical;
        color: $color-grey-100;
        display: -webkit-box;
        font-size: rem-calc(16px);
        font-weight: $font-weight-800;
        -webkit-line-clamp: $displayed-lines;
        line-height: rem-calc(28px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__created-by-container {
        align-items: center;
        display: flex;
        gap: math.div($spacing-m, 2);
    }

    &__img {
        align-items: center;
        background: $color-navy-10;
        border-radius: 50%;
        display: flex;
        height: 18px;
        justify-content: center;
        width: 18px;
    }

    &__restricted-indicator {
        fill: $color-grey-60;
    }

    &__bottom-content {
        display: flex;
        flex-direction: column;
        gap: math.div($spacing-xs, 2);
    }

    &__user-name {
        color: $color-grey-100;
        font-size: rem-calc(12px);
        font-weight: $font-weight-800;
        line-height: rem-calc(18px);
    }

    &__date {
        color: $color-grey-80;
        font-size: rem-calc(12px);
        font-weight: $font-weight-500;
        line-height: rem-calc(18px);
    }
}
