@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Regular.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 250;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Extra Light.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 300;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Light.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Medium.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Semi Bold.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Bold.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 800;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Bold.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 900;
    src: local('Sofia Pro'), url('../../assets/fonts/Sofia Pro Black.otf');
}
