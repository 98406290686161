@import 'functions';

.description-list {
    margin: 0;

    dl,
    dd {
        margin: 0;
    }

    dt {
        font-size: rem-calc(14px);
    }
}
