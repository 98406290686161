@import 'spacing';

.mat-mdc-snack-bar-container {
    margin: 0 !important;
}

.mdc-snackbar {
    &__surface {
        height: 48px;
        margin-bottom: $spacing-xxl;
        width: 486px;
    }
}
