@import 'variables';

html {
    box-sizing: border-box;
}

html,
body {
    font-family: $font-family;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.icon-container {
    display: flex;
}
