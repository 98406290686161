@use 'sass:math';
@import 'variables', 'functions';

@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    width: 1px;

    &:not(caption) {
        position: absolute;
    }
}

@mixin grid-column($size-col-count, $custom-gutter: $gap-size, $hasGaps: true) {
    $column-width: get-single-column-width() * $size-col-count;

    box-sizing: border-box;
    display: inline-grid;
    grid-template-columns: 100%;
    width: $column-width;
    word-break: break-all;

    @if $hasGaps {
        $_single-gap-size: math.div($gap-size, 2);

        padding-left: $_single-gap-size;
        padding-right: $_single-gap-size;
    }
}

@mixin ellipsis-overflow($font-size) {
    //This margin-right is fix for Firefox ellipsis, to not cut short strings
    $margin-right: math.div($font-size, 3.5);

    flex-grow: 1;
    margin-right: $margin-right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    // This is a fix for iOS for overflow to work as expected
    &::after {
        content: '\0000a0';
        display: inline-block;
        width: 0;
    }
}
