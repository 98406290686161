@use 'sass:math';
@import 'variables', 'spacing', 'colors', 'functions', 'mixins';

.field {
    font-family: $font-family;
    font-size: rem-calc(14px);
    width: 100%;

    .ng-invalid.ng-touched {
        outline: $field-border-invalid;
    }

    &__control-wrapper {
        height: $field-height-large;
        position: relative;

        &--medium {
            height: $field-height-medium;
        }
    }

    &__control {
        border: none;
        border-radius: 4px;
        box-sizing: border-box;
        display: block;
        font-family: $font-family;
        height: 100%;
        line-height: rem-calc(24px);
        outline: $field-border-primary;
        padding: math.div($spacing-m, 2) $spacing-s;
        width: 100%;

        &--with-left-icon {
            padding-left: $spacing-xl;
        }

        &--with-right-icon {
            padding-right: $spacing-xl;
        }

        &:disabled {
            background-color: transparent;
            outline: $field-border-disabled;
        }

        &:focus {
            // important is added to override specific error styling
            outline: $field-border-focus !important;
        }
    }

    &__icon {
        align-items: center;
        display: flex;
        height: 100%;
        padding: math.div($spacing-m, 2);
        position: absolute;
        top: 0;

        button {
            display: flex;
        }

        svg-icon {
            fill: $color-grey-80;
        }

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }
    }

    &__error,
    &__hint {
        font-size: rem-calc(12px);
        height: rem-calc($field-notice-area-height);
        line-height: rem-calc($field-notice-area-height);
        margin-top: math.div($spacing-s, 4);
    }

    &__error {
        color: $color-red-primary;
    }

    &__hint {
        color: $color-grey-80;
    }

    &__label {
        align-items: center;
        display: inline-flex;
        font-weight: $font-weight-800;
        line-height: rem-calc(24px);
        margin: 0 0 math.div($spacing-xs, 2);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--visually-hidden {
            @include visually-hidden;
        }

        &--required {
            color: $color-red-primary;
            font-weight: $font-weight-400;
        }

        &--clean {
            font-weight: $font-weight-400;
            margin: 0;
        }
    }

    &__label-content {
        display: flex;
        gap: $spacing-xs;
        width: 100%;
    }

    &__label-title-wrapper {
        align-items: center;
        display: grid;
        grid-template-columns: auto auto auto;
    }

    &__label-title-text {
        $font-size: 16px;

        @include ellipsis-overflow($font-size);
    }

    &__label-title-icons {
        display: flex;
        gap: math.div($spacing-xs, 2);
    }

    &__custom-field-icon {
        display: flex;
        fill: $color-aqua-100;
    }

    &__tooltip {
        fill: $color-grey-80;
        height: rem-calc(18px);
        margin: math.div($spacing-xs, 4) 0 0 math.div($spacing-xs, 2);

        &--clear-margins {
            margin: 0;
        }
    }
}
