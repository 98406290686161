@use 'sass:math';
@import 'variables', 'spacing', 'colors';

.info-banner {
    align-items: center;
    background-color: $color-grey-10;
    border-radius: math.div($border-radius-global, 2);
    display: flex;
    gap: math.div($spacing-m, 2);
    padding: $spacing-s;

    &__items {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing-xxl;
    }

    &__item {
        text-align: left;

        dt {
            font-weight: $font-weight-800;
        }
    }
}
