@use 'sass:math';
@import 'functions', 'colors', 'spacing', 'mixins';

.slide-out {
    background-color: $color-white;
    bottom: 0;
    display: grid;
    grid-template-rows: $product-header-height auto $product-header-height;
    max-width: 720px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
    width: 720px;
    z-index: 999;

    &--opened {
        transform: translateX(0%);
    }

    &__notice {
        color: $color-grey-80;
        line-height: rem-calc(24px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: $spacing-m;
        overflow: auto;
        padding: $spacing-xl $spacing-xxl;
    }

    &__header {
        align-items: center;
        border-bottom: $border-primary;
        display: flex;
        gap: $spacing-xs;
        justify-content: space-between;
        padding: math.div($spacing-m, 2) $spacing-m math.div($spacing-m, 2)
            $spacing-xxl;

        button {
            height: 100%;
        }
    }

    &__title {
        $font-size: rem-calc(24px);

        color: $color-grey-100;
        @include ellipsis-overflow($font-size);
    }

    &__footer {
        align-items: center;
        border-top: $border-primary;
        display: flex;
        justify-content: space-between;
        padding: $spacing-xs $spacing-l;
    }

    &__footer-right {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
    }

    &__close-button {
        svg-icon {
            fill: $color-grey-100;
        }
    }
}
