@use 'sass:math';
@import 'variables';
@import 'spacing';

mat-tooltip-component {
    .mdc-tooltip {
        &__surface {
            background-color: $color-grey-70;
            border: 0.5px solid $color-grey-80;
            border-radius: 1px;
            color: $color-white;
            font-family: $font-family;
            font-size: rem-calc(12px);
            font-weight: $font-weight-500;
            line-height: rem-calc(18px);
            margin-bottom: $spacing-xl;
            padding: math.div($spacing-xs, 2) $spacing-xs;
        }
    }
}
