/* stylelint-disable color-hex-length */
$primary-palette: (
    50: #e0eff6,
    100: #b3d7ea,
    200: #80bcdc,
    300: #4da1cd,
    400: #268cc3,
    500: #0078b8,
    600: #0070b1,
    700: #0065a8,
    800: #005ba0,
    900: #004891,
    A100: #bdd9ff,
    A200: #8abbff,
    A400: #579eff,
    A700: #3d8fff,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);

$accent-palette: (
    50: #e9f7f6,
    100: #c8ece9,
    200: #a4e0db,
    300: #80d3cc,
    400: #64c9c1,
    500: #49c0b6,
    600: #42baaf,
    700: #39b2a6,
    800: #31aa9e,
    900: #219c8e,
    A100: #d8fffa,
    A200: #a5fff4,
    A400: #72ffee,
    A700: #58ffeb,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$warn-palette: (
    50: #f7e5eb,
    100: #ecbfcc,
    200: #e094ab,
    300: #d36989,
    400: #c9496f,
    500: #c02956,
    600: #ba244f,
    700: #b21f45,
    800: #aa193c,
    900: #9c0f2b,
    A100: #ffccd5,
    A200: #f9a,
    A400: #ff6680,
    A700: #ff4d6a,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: #000,
        A200: #000,
        A400: #000,
        A700: #000,
    ),
);
