@import 'colors', 'functions';

$access-type-font-size: 14px;

.access-type {
    align-items: center;
    color: $color-grey-80;
    display: flex;
    fill: $color-grey-80;
    font-size: rem-calc($access-type-font-size);
    gap: $spacing-xs;
    white-space: nowrap;
}
