@use 'sass:math';
@import 'spacing', 'variables', 'functions';

.radio-group {
    align-items: center;
    display: flex;
    height: $field-height-large;
    // Compensating `field-notice-area`
    margin-bottom: rem-calc(
        $field-notice-area-height + math.div($spacing-xs, 2)
    );

    &.ng-invalid.ng-touched {
        .mdc-radio {
            &__inner-circle,
            &__outer-circle {
                border-color: $color-red-primary !important;
            }
        }
    }

    &--medium {
        height: $field-height-medium;
    }

    &__radio-buttons-container {
        display: flex;
        flex-wrap: nowrap;
        gap: $spacing-l;

        &:first-child {
            .mdc-radio {
                padding-left: 0;
            }
        }
    }
}
