a {
    background-color: transparent;
    border: none;
    color: inherit;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}

button {
    background-color: transparent;
    background-image: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-weight: inherit;
    height: auto;
    line-height: inherit;
    padding: 0;
    text-align: inherit;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }
}

ul {
    list-style: none;
    padding: 0;
}

svg-icon {
    display: inline-flex;
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
    display: none;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
    display: none !important;
    height: 0;
    margin: 0;
    pointer-events: none;
    visibility: hidden;
    width: 0;
}
