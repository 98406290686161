@use 'sass:math';
@import 'colors', 'variables', 'spacing', 'functions';

.expansion-panel {
    $this: &;

    background: $color-white;
    border: $border-primary;
    border-radius: $border-radius-global;
    color: $color-grey-100;
    display: flex;
    flex-direction: column;
    padding: $spacing-s;

    &--no-border {
        border: 0;
        padding: 0;
    }

    &__status-checkbox {
        margin-top: math.div($spacing-l, 2);
    }

    &__toggler {
        display: flex;
    }

    &__header {
        align-items: center;
        color: $color-grey-100;
        display: flex;
        font-weight: $font-weight-800;
        gap: $spacing-s;
        padding-right: $spacing-xs;
        width: 100%;

        &--hidden {
            display: none;
        }
    }

    &__header-actions {
        display: flex;
        gap: $spacing-xs;

        &:empty {
            display: none;
        }
    }

    &__toggle-icon {
        fill: $color-blue-primary;
        transform: rotateZ(0deg);

        &--toggled {
            transform: rotateZ(-180deg);
        }
    }

    &__header-title {
        align-items: center;
        display: flex;
        flex-grow: 1;
        gap: math.div($spacing-m, 2);
        line-height: rem-calc(40px);
        padding-left: $spacing-xs;
        word-break: break-word;

        &:hover {
            background-color: $color-grey-10;
            border-radius: math.div($border-radius-global, 2);
            cursor: pointer;
        }
    }

    &__header-title-text {
        align-items: center;
        display: flex;
        font-size: rem-calc(20px);

        &--bright {
            color: $color-grey-100;
        }

        &--pale {
            color: $color-grey-60;
        }
    }

    &__missing-information {
        align-items: center;
        background-color: $color-grey-10;
        border: 1px solid $color-grey-100;
        border-radius: $border-radius-global;
        display: flex;
        font-size: rem-calc(12px);
        gap: $spacing-xs;
        height: 32px;
        line-height: rem-calc(18px);
        padding: $spacing-xs math.div($spacing-m, 2);

        svg-icon {
            fill: $color-grey-100;
        }
    }

    &__required-information {
        color: $color-red-primary;
        display: inline-block;
        font-size: rem-calc(12px);
        font-weight: $font-weight-500;
        min-width: 220px;
        padding-left: $spacing-xs;
    }

    &__content {
        display: none;
        margin-top: $spacing-m;
        padding: 0 $spacing-xs;

        &--expanded {
            display: flex;
            flex-direction: column;
            gap: $spacing-m;
            padding-bottom: $spacing-xs;
        }

        &--no-spacing {
            margin-top: 0;
            padding: 0;
        }
    }

    &__content-notice {
        color: $color-grey-80;
        line-height: rem-calc(24px);
    }

    &__content-footer {
        display: flex;
        gap: math.div($spacing-m, 2);
        justify-content: flex-end;

        &:empty {
            display: none;
        }
    }

    &__groups-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-m;
    }

    &__group {
        border: $border-primary;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: $spacing-m;
        padding: $spacing-s $spacing-m $spacing-m;
    }

    &__group-title {
        font-size: rem-calc(20px);
        font-weight: $font-weight-800;
    }

    &__last-updated {
        color: $color-grey-80;
        font-size: rem-calc(12px);
        text-align: end;
        width: 100%;
    }
}
