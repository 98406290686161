@import 'spacing';
@import 'colors';
@import 'functions';

.panel {
    align-items: center;
    background-color: $color-white;
    border: $border-primary;
    border-radius: $border-radius-global;
    display: flex;
    fill: $color-primary-blue;
    gap: $spacing-l;
    justify-content: space-between;
    margin-bottom: $spacing-m;
    padding: $spacing-m;
    width: 100%;

    &:hover {
        box-shadow: 0 2px 12px rgb(0 0 0 / 25%);
        cursor: pointer;
    }

    h3 {
        color: $color-grey-100;
        font-size: rem-calc(20px);
        margin-bottom: $spacing-s;
    }

    p {
        color: $color-grey-80;
        font-weight: $font-weight-400;
        line-height: rem-calc(24px);
    }
}
