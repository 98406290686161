@use 'sass:math';
@import 'colors', 'variables', 'spacing';

.dropdown {
    &__label-icon {
        display: flex;
        margin-top: math.div($spacing-xs, 2);

        &:empty {
            display: none;
        }

        svg {
            fill: $color-yellow-100;
        }
    }

    .mat-mdc-select {
        outline: $field-border-primary;
    }

    .mat-mdc-select-disabled {
        background-color: transparent;
        outline: $field-border-disabled;
        pointer-events: none;
    }

    &__control {
        background-color: $color-white;
        padding: 0;

        .mat-mdc-select-trigger {
            height: 100%;
            padding: math.div($spacing-m, 2) $spacing-s;
        }
    }

    &__option {
        word-break: break-word;
    }
}

.dropdown-panel {
    padding: math.div($spacing-xs, 2) 0 !important;

    .mat-mdc-option {
        min-height: 32px;

        &.mdc-list-item--selected:not(.mdc-list-item--disabled) {
            background: $color-blue-10;
        }

        &:hover:not(.mdc-list-item--disabled),
        &:focus:not(.mdc-list-item--disabled),
        &.mdc-list-item--selected:hover:not(.mdc-list-item--disabled) {
            background: $color-grey-10;
        }
    }

    .mat-pseudo-checkbox-checked {
        background-color: $color-blue-primary;
    }

    .mdc-list-item {
        &__primary-text {
            color: $color-grey-100;
            display: flex;
            justify-content: space-between;
            width: 100%;

            svg-icon {
                fill: $color-blue-primary;
            }
        }
    }
}
