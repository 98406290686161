@import 'mixins', 'functions';

.col-no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.flex-grow {
    flex-grow: 1;
}

.mb-m {
    margin-bottom: $spacing-m;
}

.col-1 {
    @include grid-column(1);
}

.col-2 {
    @include grid-column(2);
}

.col-3 {
    @include grid-column(3);
}

.col-4 {
    @include grid-column(4);
}

.col-5 {
    @include grid-column(5);
}

.col-6 {
    @include grid-column(6);
}

.col-7 {
    @include grid-column(7);
}

.col-8 {
    @include grid-column(8);
}

.col-9 {
    @include grid-column(9);
}

.col-10 {
    @include grid-column(10);
}

.col-11 {
    @include grid-column(11);
}

.col-12 {
    @include grid-column(12);
}
