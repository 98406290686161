@import 'spacing', 'colors', 'variables', 'functions';

.empty-state {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
    height: 100%;
    margin: 0 auto;
    max-width: 360px;
    padding-bottom: $spacing-xxl;
    text-align: center;

    &--condensed {
        border: $border-primary;
        border-radius: $border-radius-global;
        color: $color-grey-100;
        max-width: 100%;
        padding: $spacing-m;
    }

    &--padding-top {
        padding-top: 200px;
    }

    &__title {
        color: $color-grey-100;
        font-size: rem-calc(20px);
    }

    &__icon {
        svg-icon svg {
            fill: $color-grey-80;
        }
    }

    &__notice {
        color: $color-grey-80;
        font-size: rem-calc(14px);
    }
}
