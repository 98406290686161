@use 'sass:math';
@import 'colors', 'variables', 'spacing';

.tabs {
    .mat-mdc-tab-group {
        height: calc(100% - $tab-height);
    }

    .mat-mdc-tab-header {
        height: $tab-height;
    }

    .mat-mdc-tab-body-wrapper {
        flex-grow: 1;
    }

    .mat-mdc-tab:hover {
        .mdc-tab__ripple::before {
            background-color: $color-grey-20;
            opacity: 1;
        }
    }

    .mat-mdc-tab-label-container {
        border-bottom: $border-primary;
    }

    .mat-mdc-tab.mdc-tab {
        height: $tab-height;
    }

    .mdc-tab {
        &__text-label {
            color: $color-grey-80;
            padding-bottom: math.div($spacing-xs, 2);
            transition: none;
        }
    }

    .mdc-tab--active {
        .mdc-tab__text-label {
            color: $color-black !important;
            font-weight: $font-weight-800;
        }

        .mdc-tab-indicator__content {
            &--underline {
                border-color: $color-aqua-100;
                border-width: 4px;
            }
        }
    }
}
