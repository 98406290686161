@use 'sass:math';
@import 'spacing', 'variables', 'functions';

.checkbox-group {
    align-items: center;
    display: flex;
    height: $field-height-large;
    // Compensating `field-notice-area`
    margin-bottom: rem-calc(
        $field-notice-area-height + math.div($spacing-xs, 2)
    );

    .mat-mdc-checkbox.ng-invalid.ng-touched {
        .mdc-checkbox {
            &__background {
                border-color: $color-red-primary !important;
            }
        }
    }

    &--medium {
        height: $field-height-medium;
    }

    &__checkboxes-container {
        display: flex;
        flex-wrap: nowrap;
        gap: $spacing-l;
        position: relative;

        &:first-child {
            .mdc-radio {
                padding-left: 0;
            }
        }
    }

    &__label {
        font-family: $font-family;
        font-size: rem-calc(14px);
    }
}
