@use 'sass:math';
@import 'spacing', 'colors', 'functions';

.report {
    &__form {
        display: grid;
        gap: $spacing-m;
        grid-template-columns: repeat(auto-fill, 250px);
    }

    &__flex-centered {
        align-items: center;
        display: flex;
        gap: $spacing-xs;
    }

    &__img {
        background: $color-navy-10;
        border-radius: 50%;
        height: 24px;
        width: 24px;
    }

    &__finalized-by {
        align-items: center;
        display: flex;
        font-size: rem-calc(14px);
        gap: $spacing-xs;
    }
}
