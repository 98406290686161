/* stylelint-disable color-hex-length */
$color-navy-10: #ebf7fd;
$color-navy-60: #758a9a;
$color-navy-80: #466377;
$color-navy-100: #063d58;

$color-aqua-10: #ecf8f7;
$color-aqua-20: #e6ecee;
$color-aqua-100: #49c0b6;

$color-green-10: #e5f3ed;

$color-grey-10: #f8f8f8;
$color-grey-20: #efefef;
$color-grey-50: #d7d7d7;
$color-grey-60: #929292;
$color-grey-70: #7c7c7c;
$color-grey-80: #666666;
$color-grey-90: #505050;
$color-grey-100: #3a3a3a;

$color-orange-10: #fae5d5;
$color-orange-100: #d04a00;

$color-yellow-10: #fefbee;
$color-yellow-50: #f7d514;
$color-yellow-100: #debe08;

$color-blue-primary: #0078b8;
$color-blue-10: #ebf7fd;

$color-red-primary: #c02956;
$color-red-10: #fae8ed;
$color-red-100: #811c3a;

$color-black: #000;
$color-white: #fff;
$color-content-background: #e5e5e5;

$color-primary-blue: #0078b8;
$color-primary-green: #008850;
$color-primary-orange: #e98031;

.color-red-primary {
    color: $color-red-primary;
}
