@import 'colors';

.mtx-datetimepicker-toggle {
    color: $color-grey-80;
}

.mtx-time-ampm .mtx-time-am.mtx-time-ampm-active,
.mtx-time-ampm .mtx-time-pm.mtx-time-ampm-active,
.mtx-time-input.mtx-time-input-active,
.mtx-calendar-header .mat-mdc-button {
    background-color: $color-blue-primary;
    color: $color-white !important;
}

.mtx-time-input.mtx-time-input-active:focus {
    color: $color-black;
}

.mtx-calendar-body-cell-content.mtx-calendar-body-selected.mtx-calendar-body-today {
    box-shadow: none;
}

.datetimepicker {
    &--hidden {
        display: none;
    }

    &__toggle {
        right: -14px;

        .mat-mdc-icon-button.mat-mdc-button-base {
            padding: 12px 14px 14px;
        }
    }
}
