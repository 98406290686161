.datepicker {
    &--hidden {
        display: none;
    }

    &__date-picker-toggle {
        right: -14px;

        .mat-mdc-icon-button.mat-mdc-button-base {
            padding: 12px 14px 14px;
        }
    }
}
