@import 'colors', 'spacing';

// Layout
$product-header-height: 60px;
$main-nav-width: 256px;
$breadcrumb-height: 18px;
$page-header-height: 48px;
$tab-height: 36px;
$max-layout-width: 1200px;
$layout-column-count: 12;
$gap-size: $spacing-s;
$sticky-footer-height: 56px;

// Typography
$font-size-px-base: 16px;
$font-family: 'Sofia Pro', sans-serif;

$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$font-weight-800: 800;

$border-primary: 1px solid $color-grey-50;
$border-radius-global: 8px;

// Field
$field-height-large: 48px;
$field-height-medium: 40px;
$field-notice-area-height: 18px;
$field-border-primary: 1px solid $color-grey-80;
$field-border-disabled: 1px solid $color-grey-50;
$field-border-focus: 2px solid $color-blue-primary;
$field-border-invalid: 2px solid $color-red-primary;
