@use 'sass:math';

@import 'variables', 'spacing', 'functions', 'mixins';

.link-tab-group {
    border-bottom: $border-primary;
    display: flex;
    flex-direction: row;
}

.link-tab {
    color: $color-grey-80;
    height: $tab-height;
    line-height: rem-calc(36px);
    overflow: hidden;
    padding: 0 $spacing-m;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        background-color: $color-grey-20;
    }

    &--active {
        border-bottom: 4px solid $color-aqua-100;
        color: $color-black;
        font-weight: $font-weight-800;
    }
}
