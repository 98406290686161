@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

/* stylelint-disable no-invalid-position-at-import-rule */
@import 'palettes';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ui-primary: mat.m2-define-palette($primary-palette);
$ui-accent: mat.m2-define-palette($accent-palette);

// The warn palette is optional (defaults to red).
$ui-warn: mat.m2-define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ui-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $ui-primary,
            accent: $ui-accent,
            warn: $ui-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ui-theme);
@include mtx.all-component-themes($ui-theme);
