@use 'sass:math';

@import 'functions';
@import 'spacing';
@import 'colors';
@import 'variables';

$context-menu-padding: math.div($spacing-xs, 2) $spacing-xs;
$context-menu-border-radius: 4px;

.context-menu {
    gap: math.div($spacing-xs, 2);
    padding: $context-menu-padding;

    &__trigger {
        align-items: center;
        color: $color-grey-100;
        display: flex;
        flex-direction: row;
        gap: math.div($spacing-xs, 2);

        &:hover {
            background-color: $color-grey-20;
            border-radius: $context-menu-border-radius;
        }
    }

    &__trigger-content {
        align-items: center;
        display: flex;
        font-size: rem-calc(16px);
        gap: math.div($spacing-xs, 2);
        padding: math.div($spacing-xs, 4) math.div($spacing-xs, 2)
            math.div($spacing-xs, 4) $spacing-xs;
    }

    &__items {
        .mat-mdc-menu-content {
            align-items: flex-start;
            border-radius: $context-menu-border-radius;
            display: flex;
            flex-direction: column;
            min-width: 200px;
            padding: math.div($spacing-xs, 2) 0;
        }
    }

    &__item {
        .mdc-list-item__primary-text {
            align-items: center;
            color: $color-grey-100;
            display: flex;
            font-size: rem-calc(14px);
            font-weight: $font-weight-400;
            gap: math.div($spacing-xs, 2);
            justify-content: space-between;
            width: 100%;
        }

        &:hover {
            background-color: $color-grey-10;
        }

        &--selected {
            background-color: $color-navy-10 !important;
        }

        &--checked {
            fill: $color-blue-primary;
        }
    }
}
