@import 'functions', 'spacing';

.error {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: 645px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: $spacing-l;
    }

    &__status-number {
        font-size: rem-calc(64px);
    }

    &__notice {
        font-size: rem-calc(28px);
    }
}
