@use 'sass:math';
@import 'colors', 'spacing', 'variables', 'functions';

.chip {
    border-radius: 8px;
    color: $color-grey-100;
    font-size: rem-calc(12px);
    font-weight: $font-weight-800;
    outline: $border-primary;
    padding: $spacing-xs math.div($spacing-m, 2);
    width: fit-content;
}
