@use 'sass:math';
@import 'variables', 'colors', 'spacing', 'functions';

.plan {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: $spacing-s;
    position: relative;
    width: 100%;

    &__header {
        align-items: center;
        background-color: $color-white;
        border-bottom: $border-primary;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        height: 72px;
        line-height: rem-calc(32px);
        padding: $spacing-s $spacing-m;
        position: sticky;
        top: 0;
        width: 100%;
    }

    &__close {
        display: flex;
        width: fit-content;
    }

    &__title {
        align-items: center;
        display: flex;
        gap: $spacing-xs;
        justify-content: center;
    }

    &__step-number {
        border: 2px solid $color-grey-100;
        border-radius: 18px;
        height: 32px;
        line-height: rem-calc(26px);
        min-width: 32px;
        text-align: center;
        width: 32px;
    }

    &__action {
        display: flex;
        justify-content: flex-end;
    }

    &__notice {
        border-right: $border-primary;
        padding-right: $spacing-xs;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: math.div($spacing-l, 2);
        margin-top: $spacing-m;
        width: $max-layout-width;
    }

    &__content-notice {
        color: $color-grey-90;
        line-height: rem-calc(24px);
    }

    &__last-updated {
        color: $color-grey-80;
        font-size: rem-calc(12px);
        text-align: end;
        width: 100%;
    }
}
